var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isCustomAddressFormOpen
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "ma-0 pb-0", attrs: { cols: "12" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.matchedPlacesList,
                      "item-text": "title",
                      "item-value": "title",
                      loading: _vm.isLoading,
                      disabled: _vm.disabled,
                      "search-input": _vm.query,
                      clearable: "",
                      "no-filter": "",
                      "return-object": "",
                      color: "#e1b753",
                      label: "Address",
                      "data-cy": "location-address-field",
                    },
                    on: {
                      "update:searchInput": function ($event) {
                        _vm.query = $event
                      },
                      "update:search-input": [
                        function ($event) {
                          _vm.query = $event
                        },
                        _vm.onSearchInputChange,
                      ],
                      change: _vm.setSelectedPlace,
                      "click:clear": function ($event) {
                        return _vm.removePlace()
                      },
                    },
                    model: {
                      value: _vm.selectedPlace,
                      callback: function ($$v) {
                        _vm.selectedPlace = $$v
                      },
                      expression: "selectedPlace",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isCustomAddressFormOpen
        ? _c(
            "div",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pb-0", attrs: { cols: 12 } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "House number + Street address",
                          "data-cy": "location-manual-edit-house-street-field",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.setCustomSelectedPlace()
                          },
                        },
                        model: {
                          value: _vm.selectedPlace.address.street,
                          callback: function ($$v) {
                            _vm.$set(_vm.selectedPlace.address, "street", $$v)
                          },
                          expression: "selectedPlace.address.street",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "City" },
                        on: {
                          input: function ($event) {
                            return _vm.setCustomSelectedPlace()
                          },
                        },
                        model: {
                          value: _vm.selectedPlace.address.city,
                          callback: function ($$v) {
                            _vm.$set(_vm.selectedPlace.address, "city", $$v)
                          },
                          expression: "selectedPlace.address.city",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "State" },
                        on: {
                          input: function ($event) {
                            return _vm.setCustomSelectedPlace()
                          },
                        },
                        model: {
                          value: _vm.selectedPlace.address.stateCode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.selectedPlace.address,
                              "stateCode",
                              $$v
                            )
                          },
                          expression: "selectedPlace.address.stateCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Zipcode" },
                        on: {
                          input: function ($event) {
                            return _vm.setCustomSelectedPlace()
                          },
                        },
                        model: {
                          value: _vm.selectedPlace.address.postalCode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.selectedPlace.address,
                              "postalCode",
                              $$v
                            )
                          },
                          expression: "selectedPlace.address.postalCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.countryCodes,
                          label: "Country",
                          "item-text": "name",
                          "item-value": "id",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.setCustomSelectedPlace()
                          },
                        },
                        model: {
                          value: _vm.selectedPlace.address.countryCode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.selectedPlace.address,
                              "countryCode",
                              $$v
                            )
                          },
                          expression: "selectedPlace.address.countryCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-tooltip",
        {
          attrs: { disabled: _vm.isPlaceSelected, right: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "div",
                    _vm._g({ staticClass: "d-inline-block" }, on),
                    [
                      _c("v-checkbox", {
                        staticClass: "my-O pt-0 pb-1",
                        attrs: {
                          disabled: !_vm.isPlaceSelected,
                          color: "primary",
                          dense: "",
                          "hide-details": "",
                          label: "Edit the address manually",
                          "data-cy": "location-manual-edit-checkbox",
                        },
                        model: {
                          value: _vm.isCustomAddressFormOpen,
                          callback: function ($$v) {
                            _vm.isCustomAddressFormOpen = $$v
                          },
                          expression: "isCustomAddressFormOpen",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [_c("span", [_vm._v("Enter the nearest address")])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }